"use strict";
;(function(){

	var slidesDataDesktop = [
		'images/engagement54.jpg',
		'images/engagement61.jpg',
		'images/engagement4.jpg',
		'images/engagement34.jpg',
		'images/engagement30.jpg',
		'images/engagement62.jpg'
	];
	var slidesDataMobile = [
		'images/engagement38-m.jpg',
		'images/engagement50-m.jpg',
		'images/engagement44-m.jpg',
		'images/engagement29-m.jpg',
		'images/engagement64-m.jpg'
	];

	var slidesData = document.documentElement.clientWidth > 600 ? slidesDataDesktop : slidesDataMobile;	

	var slides = [];


	var leftSlider = document.getElementById('left-col');

	var nameContainer = document.getElementById("name-container");
	var name1 = document.getElementById("iris");
	var name2 = document.getElementById("and");
	var name3 = document.getElementById("roberto");
	var mainTitle = document.getElementById("main-title");
	var dateInfo = document.getElementById("date-info");
	var urlPath = window.location.href.indexOf("/en") ? "../" : "";

	var transitionEvent, autoplay;

	function nextSlide() {

		slides[0].classList.remove('current');
		slides[0].classList.add('previous', 'change');
		slides[1].classList.remove('next');
		slides[1].classList.add('current');
		slides[2].classList.add('next');
		var last = slides.length - 1;
		slides[last].classList.remove('previous');


		var placeholder = slides.shift();
		slides.push(placeholder);
	}

	function customFunction(event) {
		event.currentTarget.removeEventListener(transitionEvent, customFunction);
	}

	function whichTransitionEvent() {
		var t,
			el = document.createElement("fakeelement");

		var transitions = {
			"transition": "transitionend",
			"OTransition": "oTransitionEnd",
			"MozTransition": "transitionend",
			"WebkitTransition": "webkitTransitionEnd"
		}

		for (t in transitions) {
			if (el.style[t] !== undefined) {
				return transitions[t];
			}
		}
	}

	transitionEvent = whichTransitionEvent()

	autoplay = setInterval(function () {
		nextSlide();
	}, 5000);

	var firstSlide = new Image();
	firstSlide.onload = function(){
		leftSlider.classList.remove("faded");
		loadImages();
	}
	firstSlide.src = urlPath+slidesData[0];



	function loadImages(){
		for (var i = 0; i < slidesData.length; i++) {
			var slide = document.createElement('div');
			slide.classList.add('slide');
			slide.setAttribute('style', 'background:url(' + urlPath+slidesData[i] + ')');

			switch (i) {
				case 0:
					slide.classList.add('current');
					break;
				case 1:
					slide.classList.add('next');
					break;
				case slidesData.length - 1:
					slide.classList.add('previous');
					break;
				default:
					break;
			}
			slide.addEventListener(transitionEvent, customFunction);
			slides.push(slide);
			leftSlider.appendChild(slide);
		}
	}


	setTimeout(function(){
		name1.classList.remove("up-fade");
	},500);
	setTimeout(function(){
		name2.classList.remove("down-fade");
		nameContainer.classList.remove("unlined");
	}, 800);
	setTimeout(function(){
		name3.classList.remove("up-fade");
		
	}, 700);
	setTimeout(function(){
		mainTitle.classList.remove("down-fade");
		dateInfo.classList.remove("faded");
	}, 1500);

})()